<template>
  <v-app id="app" style="background-color: #ffffff">
    <!-- <center>
      <img src="@/assets/mohser.jpeg" height="200px" />
    </center> -->

    <v-main>
      <v-container fluid>
        <vue-page-transition name="fade">
          <router-view />
        </vue-page-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({
    menu: [
      // { title: "الرئيسية", icon: "ti-home", path: "/" },
      // { title: "الطلاب", icon: "ti-users", path: "/students" },
      // { title: "الحسابات", icon: "ti-coin", path: "/accountant" },
      // { title: "الاقساط", icon: "ti-receipt-2", path: "/payments" },
      // { title: "التقارير", icon: "ti-report", path: "/reports" },
    ],
  }),
  methods: {
    logout() {
      localStorage.clear();
      location.reload();
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    userInfo() {
      return this.$store.getters.getLoginInfo;
    },
    currentYear() {
      return this.$store.getters.getCurrentYear;
    },
    mySection() {
      return this.$store.getters.getMySection;
    },
  },
};
</script>

<style src="./style.css"></style>