<template>
  <div class="home">
    <v-card v-if="!sendSuccess" class="pa-10">
      <center>
        <h3>
          استمارة الحصول على تخفيض للمتقدمين على الكليات الاهلية 2023-2024
        </h3>
        <br />
      </center>
      <!-- @keyup="send()" -->
      <v-text-field v-model="form.phone" outlined rounded label="رقم الهاتف"></v-text-field>
      <v-text-field v-model="form.name" outlined rounded label="الاسم الطالب الرباعي"></v-text-field>
      <v-autocomplete outlined rounded v-model="form.location" label="المحافظة" :items="provinces"></v-autocomplete>
      <v-autocomplete outlined rounded v-model="form.examNumber" label="اختر نوع التخفيض"
        :items="discountTypes"></v-autocomplete>
      <v-text-field v-model="form.degree" outlined rounded label="المعدل بعد الاضافة"></v-text-field>
      <v-autocomplete v-model="form.section" label="القسم" :items="sections" outlined rounded
        @change="send()"></v-autocomplete>
      <small style="color: red">* في حال اختيار قسم لا يقبل معدلك ستهمل استمارتك</small>
      <br>
      <small style="color: red">* ارسالك للاستمارة بشكل مكرر يهمل الاستمارة ولايتم الاتصال بك</small>
      <br>
      <br>
      <!-- <v-text-field
        v-model="form.examNumber"
        outlined
        rounded
        
        label="الرقم الامتحاني"
      ></v-text-field>
      <v-text-field
        v-model="form.examPass"
        outlined
        rounded
        
        label="الرقم السري"
      ></v-text-field> -->
      <v-btn @click="sendForm()" block color="primary" x-large>ارسال</v-btn>
      <br />
      <br />

      <div style="max-width: 100%">
        <h3></h3>
        <br /><br />

        <br /><br />
      </div>
    </v-card>
    <v-card v-if="sendSuccess" color="success" class="text-center pa-10">
      تم ارسال الاستمارة بنجاح
      <br>
      <b>يرجى الانتباه للهاتف , سوف نتصل بكم خلال ساعات</b>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data: () => ({
    sendSuccess: false,
    form: {
      name: "",
      phone: "",
      location: "",
      degree: "",
      section: "",
      examNumber: "",
      examPass: "111",
    },
    provinces: [
      "بغداد",
      "الانبار",
      "بابل",
      "أربيل",
      "البصرة",
      "حلبجة",
      "دهوك",
      "القادسية",
      "ديالى",
      "ذي قار",
      "سليمانية",
      "صلاح الدين",
      "كركوك",
      "كربلاء",
      "المثنى",
      "ميسان",
      "النجف",
      "نينوى",
      "واسط"
    ],
    sections: [
      "تقنيات صناعة الاسنان",
      "طب الاسنان",
      "بصريات",
      "اشعة وسونار",
      "تحليلات مرضية",
      "تقنيات التخدير",
      "هندسة الليزر والالكترونيات البصرية",
      "هندسة مدني",
      "هندسة التكييف والتبريد",
      "هندسة الاجهزة الطبية",
      "الاعلام",
      "اللغة العربية",
      "اللغة الانكليزية",
      "علوم الحاسوب",
      "هندسة تقنيات الحاسوب",
      "التربية الرياضية",
      "القانون",
      "تقنيات التصميم الداخلي",
      "التربية الفنية",
      "الفنون التشكيلية",
      "ادارة الاعمال",
      "المحاسبة",
      "المالية والمصرفية",
      "تقنيات المختبرات الطبية",
      "اسلامية",
      "تاريخ",
      "جغرافية",
      "فلسفة",
      "علوم رياضيات",
      "العلاج الطبيعي",
      "هندسة الاتصالات",
      "قسم المعلومات وتقنية المعرفة",
      "صيدلة"
    ],
    discountTypes: [
      "ايتام",
      "ذوي الشهداء",
      "ذوي الدخل المحدود",
      "ذوي السجناء السياسيين",
      "رعاية اجتماعية",
      "قرى وارياف",
      "اخرى"
    ],
  }),
  methods: {
    send() {
      if (this.form.phone.length >= 11 && this.form.degree != null) {
        this.$http.post("forms", this.form).then((res) => {
          console.log(res.data);
        });
      }
    },
    sendForm() {
      if (
        this.form.phone.length >= 10 &&
        this.form.degree != "" &&
        this.form.name != "" &&
        this.form.location != "" &&
        this.form.section != "" &&
        this.form.examNumber != "" &&
        this.form.examPass != ""
      ) {
        this.$http.post("forms", this.form).then((res) => {
          console.log(res.data);
          this.sendSuccess = true;
        });
      } else {
        this.$toast.open({
          type: "warning",
          message: "يرجى ملئ جميع الحقول",
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style>
.v-image__image {
  margin: 5px !important;
}

a {
  text-decoration: none;
}
</style>